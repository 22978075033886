import { v4 as uuidv4 } from 'uuid'

export default {
  selectSubsis (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo_subsis,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo_subsis.idorden_trabajo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo)
        )
      )
      .exec()
  },
  _selectLplantillaArticulo (Vue, idplantillaGrupoArticulo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.plantilla_grupo_articulo)
      .innerJoin(
        tables.lplantilla_articulo,
        tables.plantilla_grupo_articulo.idplantilla_grupo_articulo.eq(tables.lplantilla_articulo.idplantilla_grupo_articulo)
      )
      .innerJoin(
        tables.articulo,
        tables.lplantilla_articulo.idarticulo.eq(tables.articulo.idarticulo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.plantilla_grupo_articulo.idplantilla_grupo_articulo.eq(idplantillaGrupoArticulo)
        )
      )
      .orderBy(tables.lplantilla_articulo.orden)
      .exec()
  },
  async aplicarPlantillaArticulo (Vue, formData, idparteTrabajo) {
    const parteTrabajo = await Vue.$offline.parteTrabajo.row(idparteTrabajo)
    const idordenTrabajo = parteTrabajo.parte_trabajo.idorden_trabajo
    const ordenTrabajo = await Vue.$offline.ordenTrabajo.row(idordenTrabajo)
    let idsMateriales = []
    // grupos de plantillas
    for (const grupo of Object.values(formData)) {
      let idsubsis
      const fieldIdsubsisIdtsubsis = grupo.fields.idsubsis_idtsubsis.value
      // no existe el subsistema
      if (fieldIdsubsisIdtsubsis.tipo === 'tsubsis') {
        idsubsis = uuidv4()
        await Vue.$offline.subsis.insertSync({
          idsubsis,
          idsistema: ordenTrabajo.orden_trabajo.idsistema,
          idtsubsis: fieldIdsubsisIdtsubsis.idtsubsis,
          descripcion: grupo.fields.subsis_descripcion,
          codigo: grupo.fields.subsis_codigo,
          estado: 1,
        })
      } else {
        idsubsis = fieldIdsubsisIdtsubsis.id
      }
      await Vue.$offline.ordenTrabajoSubsis.insertOrdenTrabajoSubsis(idordenTrabajo, idsubsis)
      // articulos
      const articulos = await this._selectLplantillaArticulo(Vue, grupo.idplantilla_grupo_articulo)
      for (const articulo of articulos) {
        if (articulo.articulo.desglosar_ubicacion) {
          let count = 0
          while (count < articulo.lplantilla_articulo.unidades) {
            const material = await Vue.$offline.parteTrabajoMatsist.insertMaterialParteOtYSistema(
              articulo.articulo.idarticulo, 1, idparteTrabajo, idsubsis
            )
            idsMateriales.push(material.idmaterialSistema)
            count++
          }
        } else {
          const material = await Vue.$offline.parteTrabajoMatsist.insertMaterialParteOtYSistema(
            articulo.articulo.idarticulo, articulo.lplantilla_articulo.unidades, idparteTrabajo, idsubsis
          )
          idsMateriales.push(material.idmaterialSistema)
        }
      }
    }
    return idsMateriales
  }
}
